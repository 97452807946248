.Navbar:hover {
    cursor: pointer;
    transform: scale3D(1.02, 1.02, 1);
    font-weight: 500;
}

.Navbar {
    font-weight: 200;
    transition: 0.2s ease-out;
}
