.TextField {
    margin-top: 25px;
    border-radius: 30px;
    border: none;
    background-color: rgba(255, 255, 255, 0.314);
    padding: 15px;
    
}
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: rgba(255, 255, 255, 0.479);
    opacity: 1; /* Firefox */
  }