
.about-us {
    background-color: #8655a590;
    
    color: white;
    border-radius: 0px 80px 0px 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;

}

.info {
    border-radius: 150px 0px 150px 0px;
    background-color: rgb(0, 0, 0);
    font-size: 12px;
    font-weight: 500;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    direction: rtl;
}

.info-1 {
    border-radius: 150px 0px 150px 0px;
    background-color: rgb(0, 0, 0);
    direction: rtls;
    height: 300px;
    font-weight: 500;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}