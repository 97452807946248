*{
    font-family: 'Lexend Deca', sans-serif;
}

.services{
    background-size: 300px;
    background-repeat: no-repeat;
    background-position: center right;
    
    font-size: 100px;
    font-weight: 700;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.services-md{
    background-size: 200px;
    background-repeat: no-repeat;
    background-position: center right;
    
    font-size: 80px;
    font-weight: 700;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.services-sm{
    background-size: 150px;
    background-repeat: no-repeat;
    background-position: center right;
    
    font-size: 40px;
    font-weight: 700;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.services-xs{
    background-size: 100px;
    background-repeat: no-repeat;
    background-position: center right;
    
    font-size: 30px;
    font-weight: 700;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cover-img{
    background-size: 550px;
    background-position: center;
    background-repeat: no-repeat;
    font-size: 40px;
    font-weight: 500;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.collapsible .content {
    padding: 6px;
    background-color: white;
    color: black;
}
.collapsible .header {
    background-color: white;
    padding: 6px;
    cursor: pointer;
    color: black;

    display: flex;
    align-items: center;
    justify-content: center;
}