.Nav {
    background-color: rgba(255, 255, 255, 0.16);
    width: 750px;
    height: 48px;
    border-radius: 30px 30px 30px 30px;
    text-align: center;
    align-content: center;
    color: white;

}

.Header {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

}
#mySidenav a {
  position: fixed; /* Position them relative to the browser window */
  left: -80px; /* Position them outside of the screen */
  transition: 0.3s; /* Add transition on hover */
  padding: 15px; /* 15px padding */
  width: 100px; /* Set a specific width */
  text-decoration: none; /* Remove underline */
  font-size: 20px; /* Increase font size */
  color: white; /* White text color */
  border-radius: 0 5px 5px 0; /* Rounded corners on the top right and bottom right side */
}

#mySidenav a:hover {
  left: 0; /* On mouse-over, make the elements appear as they should */
}

/* The about link: 20px from the top with a green background */
#about {
  top: 20px;
  background-color: #000000;
}  


/* .Animation {
    height: 150px;
    margin: 0;
    width: 100vw;
    margin-left: -20px;
    max-width: max-content;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;

} */

.Container {
    
    position: absolute;
    left: 0;
    margin-left: -12px;
    margin-top: 200px;
   
    width: 100%;
      
      
  }
  
  .Animation {
    width: 100vw; /* Set the width to 100 viewport width */
    background-color: black;
    /* Add any other styles specific to the Animation element */
  }
  
  @keyframes moveLeftToRight {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  
  .MovingText {
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 100px;
    animation: moveLeftToRight 5s linear infinite; /* Adjust the animation duration as needed */
  }
  


.Portfolio {
    margin-left: 30px;
}


.Scrollbox {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 70px;
    font-weight: 100;
    color: white;
}

.Scroller {

    border-top-style: solid;
    border-right-style: none;
    border-bottom-style: solid;
    border-left-style: none;
    border-width: 0.5px;
    border-color: rgba(255, 255, 255, 0.32);
    overflow: scroll;
    height: 350px;
}

.Scroller1 {
    padding: 0px;
    margin: 0px;
    border-top-style: solid;
    border-right-style: none;
    border-bottom-style: solid;
    border-left-style: none;
    border-width: 0.5px;
    border-color: rgba(255, 255, 255, 0.32);
    overflow: scroll;
    height: 350px;


}

