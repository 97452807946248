*{
    font-family: 'Lexend deca', sans-serif;
}
.dropdown-container {
    position: relative;
  }
  
  .dropdown-content {
    position: absolute;
    top: 100%; /* Position the content below the dropdown */
    left: 0;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    opacity: 1;
    transition: 0.5s;
  }
  