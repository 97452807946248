* {
    font-family: 'Lexend Deca', sans-serif;
}

.details {
    font-size: 15px;
    font-weight: 500;
    color: white;
}

.input {
    border-radius: 12px 12px 12px 12px;
    border: none;
    height: 30px;
    font-weight: 500;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: rgba(17, 10, 10, 0.479);
    font-size: 12px;
    font-weight: bold;
    
    opacity: 1; /* Firefox */
  }

  .button {
    border-radius: 16px;
    width: 30%;
    border: none;
    font-weight: 600;
    height: 30px;
    
  }
  .request{
    cursor: pointer;
    
    transition: 0.2s;
  }
  .request:hover{
    cursor: pointer;
    transform: scale(1.1);
    
  }